import React, { useCallback, useEffect, useRef, useState } from "react";
import "react-quill/dist/quill.snow.css";
import {
  Button,
  Col,
  Input,
  Row
} from "antd";
import {
  SendOutlined
} from "@ant-design/icons";
import axios from "axios";
import { showNotification } from "../utils/commonFunctions";
import GenerateContentContainer from "../openAiChat/components/GenerateContentContainer";
import OpenAiChatItem from "../openAiChat/components/OpenAiChatItem";
import Picker from "emoji-picker-react";
import ChatFormBox from "./envigoForm";
import envigoLogo from "../images/bot-logo.webp";

let followUpEvent1 = null;
let followUpEvent2 = null;

const msgData = [
  {
    id: 1,
    text: "Hello there! 👋 I'm Envigo bot, your friendly assistant, here to help with anything you need. 😊 Just let me know what you're curious about, and I'll be happy to assist!",
    tm: 0,
  },
];

const thankYouMsg = {
  title: `Thank you!`,
  msg: `Thanks for sharing your details with us! 😊`,
};

const CustomChatBotEnvigo = () => {
  const messageRefs = useRef([]);
  useEffect(() => {
    document.body.classList.add("custom-chat-bot");
    return () => {
      document.body.classList.remove("custom-chat-bot");
    };
  }, []);
  const [state, setState] = useState({
    message: "",
    text: "",
  });

  const [messages, setMessages] = useState([]);
  const [showPicker, setShowPicker] = useState(false);
  const [currentMessages, setCurrentMessages] = useState([]);
  const [emailComponent, setEmailComponent] = useState([]);
  const hasRunRef = useRef(false);
  const lastMessageIndex = messages.length - 1;

  useEffect(() => {
    if (hasRunRef.current) return;
    hasRunRef.current = true;
    msgData.forEach((msg) => {
      console.log(msg?.text.length);
      setTimeout(() => {
        setCurrentMessages((prevMessages) => [...prevMessages, msg]);
      }, msg.tm + 0);
    });
  }, []);



  const onEmojiClick = (event, emojiObject) => {
    setState((prevState) => {
      const updatedMessage = prevState.message + emojiObject.emoji;
      return { ...prevState, message: updatedMessage };
    });
    setShowPicker(false);
  };

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e;

      console.log(value, "-------------", name)

      setState((prevState) => {
        prevState[name] = value;
        return { ...prevState };
      });
    },
    []
    );
    
    useEffect(() => {
    messageRefs?.current?.[lastMessageIndex]?.scrollIntoView?.({
                behavior: "smooth",
            })
  }, [lastMessageIndex, messages]);

  const onSend = useCallback(() => {
    clearTimeout(followUpEvent1);
    clearTimeout(followUpEvent2);
    const newMessages = messages;
    let message = state?.message?.trim();
    let emailValue = state?.emailValue?.trim();
    if (!message && !emailValue) return;
    if (emailValue) {
      // const val = emailComponent;
      setEmailComponent([{ emailValue: emailValue, index: messages.length }]);
      // setEmailComponent(val);
      setState((preState) => ({
        ...preState,
        emailValue: null,
      }));
      const payload = {
        user_id: `session_id_${global.ipaddress}`, //|| localStorage.getItem("email"),
        query: emailValue,
        email: emailValue,
      };
      axios({
        method: "POST",
        url: `https://chatbot.ibism.com/chat/ask_query`,
        data: payload,
        headers: {
          "Content-Type": "application/json",
        },
      })
        return;
    } else {
      newMessages.push({
        role: "user",
        content: message,
      });

      const payload = {
        user_id: `session_id_${global.ipaddress}`, //|| localStorage.getItem("email"),
        query: message,
      };
      newMessages.push({
        role: "assistant",
        content: "typing...",
        loading: true,
      });

      setMessages(newMessages);
      setState((prevState) => ({ ...prevState, message: "", loading: true }));
      axios({
        method: "POST",
        url: `https://chatbot.ibism.com/chat/ask_query`,
        data: payload,

        headers: {
          "Content-Type": "application/json",
        },
      })
          .then((response) => {
            const resData = response.data;
            if (!resData?.response) {
              setMessages((prevMessages) => {
                const lastMesssage = prevMessages.pop();
                console.log("remove message: ", lastMesssage);

                return [...prevMessages];
              });
            } else {
              const newMessage = {};
              let content = resData?.response;
              while (content.startsWith("\n")) {
                content = content.replace("\n", "");
              }
              newMessage.content = content;
              setMessages((prevMessages) => {
                prevMessages.pop();
                console.log("remove message: ");
                return [...prevMessages, newMessage];
              });
            }

            setState((prevState) => ({ ...prevState, loading: false }));
            // setTimeout(() => clearTimeout(followUpEvent1), 45000);
            // setTimeout(() => clearTimeout(followUpEvent2), 95000);
            if (resData?.User_exists) {
              // const val = emailComponent;
              // val.push({ showForm: true, index: messages.length + 1 });
              // setEmailComponent(val);

              setTimeout(()=>setEmailComponent([{ showForm: true, index: messages.length + 1 }]), 3000);
            }
            if(emailComponent?.[0]?.showForm && !resData?.User_exists) {
              setEmailComponent([])
            }
            followUpEvent1 = setTimeout(() => {
              const newMessage = {};
              newMessage.content = "typing...";
              newMessage.loading = true;
              newMessage.role = "assistant";
              // });
              //
              // setMessages(newMessages);
              setMessages((prevMessages) => {
                // prevMessages.pop();
                console.log("remove message: ");
                return [...prevMessages, newMessage];
              });
              setState((prevState) => ({ ...prevState, loading: true }));

              axios({
                method: "POST",
                url: `https://chatbot.ibism.com/chat/followup`,
                data: {
                  user_id: `session_id_${global.ipaddress}`,
                },
                headers: {
                  "Content-Type": "application/json",
                },
              }).then((response) => {
                const resData = response.data;
                // console.log(resData);

                if (!resData?.response) {
                  setMessages((prevMessages) => {
                    const lastMesssage = prevMessages.pop();
                    console.log("remove message: ", lastMesssage);

                    return [...prevMessages];
                  });
                } else {
                  const newMessage = {};
                  let content = resData?.response;
                  while (content.startsWith("\n")) {
                    content = content.replace("\n", "");
                  }
                  newMessage.content = content;

                  // setTimeout(
                  //     () =>
                  //         messageRefs?.current?.[lastMessageIndex + 1]?.scrollIntoView?.({
                  //             behavior: "smooth",
                  //         }),
                  //     100
                  // );

                  setMessages((prevMessages) => {
                    prevMessages.pop();
                    console.log("remove message: ");
                    return [...prevMessages, newMessage];
                  });
                }
                if (resData?.User_exists) {
                  // const val = emailComponent;
                  // val.push({ showForm: true, index: messages.length + 1 });
                  // setEmailComponent(val);
                  console.log("remove message: ", messages.length);

                  setEmailComponent([{ showForm: true, index: messages.length + 2 }]);
                }
                setState((prevState) => ({ ...prevState, loading: false }));
              });
            }, 35000);
            followUpEvent2 = setTimeout(() => {
              const newMessage = {};
              newMessage.content = "typing...";
              newMessage.loading = true;
              newMessage.role = "assistant";
              // });
              //
              // setMessages(newMessages);
              setMessages((prevMessages) => {
                // prevMessages.pop();
                console.log("remove message: ");
                return [...prevMessages, newMessage];
              });
              setState((prevState) => ({ ...prevState, loading: true }));
              axios({
                method: "POST",
                url: `https://chatbot.ibism.com/chat/followup`,
                data: {
                  user_id: `session_id_${global.ipaddress}`,
                },
                headers: {
                  "Content-Type": "application/json",
                },
              }).then((response) => {
                const resData = response.data;
                // console.log(resData);

                if (!resData?.response) {
                  setMessages((prevMessages) => {
                    const lastMesssage = prevMessages.pop();
                    console.log("remove message: ", lastMesssage);

                    return [...prevMessages];
                  });
                } else {
                  const newMessage = {};
                  let content = resData?.response;
                  while (content.startsWith("\n")) {
                    content = content.replace("\n", "");
                  }
                  newMessage.content = content;

                  setMessages((prevMessages) => {
                    prevMessages.pop();
                    console.log("remove message: ");
                    return [...prevMessages, newMessage];
                  });
                }
                if (resData?.User_exists) {
                  // const val = emailComponent;
                  // val.push({ showForm: true, index: messages.length + 1 });
                  // setEmailComponent(val);
                  console.log("remove message: ", messages.length);

                  setEmailComponent([{ showForm: true, index: messages.length + 3 }]);
                }
                setState((prevState) => ({ ...prevState, loading: false }));
              });
            }, 85000);
          })
          .catch((err) => {
            console.log("chat err: ", err);
            showNotification("error", "Something went wrong");
            setMessages((prevMessages) => {
              const lastMesssage = prevMessages.pop();
              console.log("remove message: ", lastMesssage);

              return [...prevMessages];
            });
            setState((prevState) => ({ ...prevState, loading: false }));
          });
    }


  }, [state.message, state.text, messages]);

  console.log("++++", emailComponent, messages);

  return (
    <div>
      <GenerateContentContainer containerClassName="open-ai-chat">
        <Row className="content-containe">
          <Col xs={24} sm={24} md={24}>
            <div className="center-frame">
              <div className="tab-frame-cbs">
                <div className="tab-inner-frame-cb">
                  <Col
                    className="scroll-d-cb"
                    style={{
                      height: "420px",
                      overflowY: "auto",
                      paddingRight: "5px",
                      paddingTop: "10px",
                    }}
                  >
                    {/* chat container start */}
                    <Col className="chat-container h-4/6">
                      <Col className="message-list-container">
                        {currentMessages.map((msg, i) => (
                          <>
                            <Row className="chat-item-container" key={i}>
                              <Col style={{ width: "34px" }}>
                                <div className="bot-logo"><img src={envigoLogo} alt="Bot-image" /></div>
                              </Col>

                              <Col className="chat-details-container">
                                <Row
                                  className="user-details"
                                  align="middle"
                                  justify="space-between"
                                >
                                  <span className="name">{"Alisha"}</span>
                                  <span className="full_cb">
                                    {msg.text}
                                  </span>
                                </Row>
                              </Col>
                            </Row>
                          </>
                        ))}

                        {messages?.map?.((message, i) => (
                          <div
                            key={i}
                            ref={(mRef) => (messageRefs.current[i] = mRef)}
                          >
                            <OpenAiChatItem key={i} message={message} />
                            {emailComponent.find(
                                    (o) => !o.showForm && o.index - 1 === i
                                )?.index -
                                1 ===
                                i && (
                                    <div>
                                      <ChatFormBox
                                          onSend={onSend}
                                          disable={
                                            !!emailComponent.find(
                                                (o) => !o?.showForm && o.index - 1 === i
                                            )?.emailValue
                                          }
                                          value={
                                            emailComponent.find(
                                                (o) => o.index - 1 === i
                                            )?.emailValue
                                          }
                                          setMessage={handleChange("emailValue")}
                                      />
                                    </div>
                                )}
                            {emailComponent.find(
                              (o) => o.showForm && o.index - 1 === i
                            )?.index -
                              1 ===
                              i && (
                              <div>
                                <ChatFormBox
                                  onSend={onSend}
                                  disable={
                                    !!emailComponent.find(
                                      (o) => !o?.showForm && o.index - 1 === i
                                    )?.emailValue
                                  }
                                  value={
                                    emailComponent.find(
                                      (o) => o.index - 1 === i
                                    )?.emailValue
                                  }
                                  setMessage={handleChange("emailValue")}
                                />
                              </div>
                            )}
                            {emailComponent.find(
                              (o) => !o?.showForm && o.index - 1 === i
                            )?.index -
                              1 ===
                              i && (
                              <Row
                                className="chat-item-container">
                                <Col style={{ width: "34px" }}>
                                  {/*  <img src={envigoLogo} alt="CitiusTech" /> */}
                                </Col>

                                <Col className="chat-details-container">
                                  <Row
                                    className="user-details"
                                    align="middle"
                                    justify="space-between"
                                  >
                                    <Col className="chat-details-data-form">
                                      <Col>
                                        <h4>{thankYouMsg?.title}</h4>
                                        <span className="full_cb">
                                          {thankYouMsg?.msg}
                                        </span>
                                      </Col>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            )}
                          </div>
                        ))}
                      </Col>
                    </Col>
                    {/* chat container end */}
                  </Col>

                  <Col className="mt20">
                    {/* chat input container end */}
                    <Row align="middle" wrap={false}>
                      <Col className="grow">
                        <div className="input-rel-cb">
                          <Input
                            className="chat-input"
                            value={state.message}
                            style={{ paddingRight: "70px" }}
                            placeholder="Enter your message"
                            onChange={handleChange("message")}
                            autosize={{ minRows: 1, maxRows: 4 }}
                            onPressEnter={(e) => {
                              if (e.key === "Enter" && !e.shiftKey) {
                                onSend();
                              }
                            }}
                            disabled={state.loading}
                            size="large"
                          />

                          <div className="emoji-cb">
                            <img
                              className="emoji-icon"
                              src="https://icons.getbootstrap.com/assets/icons/emoji-smile.svg"
                              onClick={() => setShowPicker((val) => !val)}
                            />

                            <Button
                              className="text-2xl btn-color"
                              type="text"
                              onClick={onSend}
                              disabled={!state.message.trim()}
                              loading={state.loading}
                              size="large"
                              icon={<SendOutlined className="text-3xl" />}
                            />
                          </div>
                        </div>
                      </Col>
                      {showPicker && (
                        <div className="popup-emoji-cb">
                          <Picker
                            pickerStyle={{ width: "100%" }}
                            onEmojiClick={onEmojiClick}
                          />
                        </div>
                      )}
                    </Row>
                    {/* chat input container end */}
                  </Col>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </GenerateContentContainer>
    </div>
  );
};

export default React.memo(CustomChatBotEnvigo);
